<template>
    <div>     
    </div>
</template>

<script>

    import Helpers from "@/utilities/Helpers";

    export default {

        mixins: [Helpers],

        async created() {  

            this.showLoading();

            localStorage.clear();
            this.$router.push('/login');

            this.hideLoading();
        }
    };
</script>